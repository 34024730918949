//

.issues {
  &--wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;

    @include media-breakpoint-up(lg) {
      background: rgba(0, 0, 0, 0.6);
    }

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &--inner {
    width: 500px;
    max-width: calc(100% - 40px);
    background: $black;
    border-radius: 5px;
    overflow: hidden;
  }

  &--top {
    background: $primary;
    padding: 10px 20px;

    h3 {
      font-size: 15px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }

  &--main {
    padding: 20px;

    select,
    input,
    textarea {
      width: 100%;
    }

    .save-issue {
      margin-left: 10px;
    }
  }
}
