$black: #2a2c2e;
$grey: #f2f2f2;
$grey-light: #e0e3e6;
$grey-medium: #868c96;
$dark-grey: #343536;
$bad: #e95151;
$primary: #d79b23;
$primaryDark: #c78c0d;
$green: #98dd32;
$good: $green;
$blue: #3d85b8;
$purple: #6810af;
$teal: #1f7979;

// breakpoints /////////////////////////////////////////////

$xs: 0;
$sm: 350px;
$md: 768px;
$lg: 1100px;
$xl: 1200px;
$xxl: 1440px;
$xxxl: 1920px;

$breakpoints: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl,
  xxxl: $xxxl,
);

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: calc(100% - 30px),
  xl: calc(100% - 100px),
  xxl: calc(100% - 100px),
  xxxl: calc(100% - 100px),
);

$grid-breakpoints: $breakpoints;

// dependencies
@import "pre/normalize";
@import "pre/mixins";
@import "pre/fonts";

// globals
@import "globals/type";
@import "globals/margins";
@import "globals/body";
@import "globals/page";
@import "globals/sidebar";

// components
@import "components/messages";
@import "components/spinner";
@import "components/section";
@import "components/buttons";
@import "components/config-setup";
@import "components/card";
@import "components/carousel";
@import "components/search-form";
@import "components/issues";
@import "components/review";
@import "components/input";
@import "components/table";
@import "components/calendar";
@import "components/my-requests";
@import "components/push-msg";

// pages
@import "pages/login";
@import "pages/media";
@import "pages/season";
@import "pages/person";
@import "pages/profile";
@import "pages/genre";
@import "pages/networks";
@import "pages/companies";
