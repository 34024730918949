.push-msg {
  &--wrap {
    position: fixed;
    bottom: 60px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1000;

    @include media-breakpoint-up(lg) {
      bottom: 10px;
      right: 10px;
    }
  }

  &--item {
    width: 200px;
    padding: 10px 20px;
    background: $dark-grey;
    border-radius: 5px;
    margin-top: 5px;
    border-left: solid 5px $blue;

    @include media-breakpoint-up(lg) {
      width: 300px;
      padding: 20px 20px;
      margin-top: 10px;
    }

    &.good {
      border-color: $good;
    }

    &.error {
      border-color: $bad;
    }
  }
}
