.login-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  min-height: 100vh;
  position: relative;
  pointer-events: all;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .logo {
    text-transform: uppercase;
    font-size: 32px;
    line-height: 1;
    color: white;
    font-family: "Khula", sans-serif;
    margin: 0;

    @include media-breakpoint-up(lg) {
      font-size: 42px;
    }

    span {
      color: orange;
    }
  }

  @include media-breakpoint-down(sm) {
    .btn {
      height: 40px;
      font-size: 14px;
    }
  }

  p {
    max-width: 300px;
  }

  input {
    display: block;
    width: 300px;
    margin-bottom: 20px;
    // appearance: none;
    border: none;
    outline: none;
    height: 40px;
    font-size: 16px;
    padding: 0 15px;
    border-bottom: solid 2px transparent;

    &:focus {
      border-bottom: solid 2px $primary;
    }
  }

  .admin-toggle {
    color: $primary;
    cursor: pointer;
  }

  .powered-by {
    position: absolute;
    right: 30px;
    bottom: 50px;
    margin: 0;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    color: rgba(white, 0.3);

    @include media-breakpoint-up(lg) {
      right: 50px;
    }

    a {
      color: $primary;
    }
  }

  .credits {
    position: absolute;
    right: 30px;
    bottom: 70px;
    margin: 0;
    font-size: 12px;
    line-height: 1;

    @include media-breakpoint-down(md) {
      text-align: right;
    }

    a {
      display: flex;
      margin-top: 5px;
      justify-content: flex-end;

      @include media-breakpoint-up(lg) {
        justify-content: flex-start;
      }
    }

    @include media-breakpoint-up(lg) {
      left: 50px;
      bottom: 50px;
      right: initial;
    }

    p {
      margin: 0;
      line-height: 1;
      font-weight: 400;
      width: 100%;
      color: rgba(white, 0.3);
      letter-spacing: 1px;

      strong {
        font-weight: 700;
      }

      span {
        font-weight: 300;
      }
    }

    svg {
      height: 8px;

      @include media-breakpoint-up(lg) {
        height: 10px;
      }

      .cls-1 {
        fill: rgba(white, 0.3);
      }
    }
  }
}

.login {
  &--inner {
    &--divide {
      text-align: center;
      margin: 10px 0;
      overflow: hidden;

      p {
        margin: 0;
        display: inline-block;
        position: relative;
        padding: 0 10px;

        &:before,
        &:after {
          content: "";
          position: absolute;
          top: calc(50% - 0.5px);
          height: 1px;
          background: rgba(white, 0.3);
          width: 500px;
        }

        &:before {
          right: 100%;
        }

        &:after {
          left: 100%;
        }
      }
    }

    .main-title {
      margin-bottom: 10px;
    }
  }
}
