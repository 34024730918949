.company {
  &--header {
    height: 150px;
    position: relative;
    margin: -25px -20px 20px;

    @include media-breakpoint-up(lg) {
      height: 200px;
      margin: -50px -50px 50px;
    }

    &--bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 0;
      opacity: 0.6;

      &--item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        filter: blur(10px);
        transform: scale(1.1);
      }
    }
  }
  &--title {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
    padding: 20px;
    height: 100%;

    img {
      max-height: 50px;
      max-width: 150px;
      margin-right: 20px;

      @include media-breakpoint-up(lg) {
        max-height: 100px;
        max-width: 300px;
        margin-right: 50px;
      }
    }

    font-size: 25px;

    @include media-breakpoint-up(lg) {
      font-size: 40px;
      padding: 50px;
    }
  }

  &--grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;

    &--card {
      width: calc(100% / 4);
      padding: 0 5px 5px;

      @include media-breakpoint-up(md) {
        width: calc(100% / 5);
      }

      @include media-breakpoint-up(lg) {
        width: calc(100% / 5);
        padding: 0 10px 10px;
      }

      @include media-breakpoint-up(xl) {
        width: calc(100% / 6);
      }

      @include media-breakpoint-up(xxl) {
        width: calc(100% / 7);
      }

      @include media-breakpoint-up(xxxl) {
        width: calc(100% / 8);
      }
    }

    .card {
      margin: 0;
    }
  }
}
