body {
  // background: red;
  max-width: 100vw;
  overflow: hidden;
  color: $black;
  background: linear-gradient(
    135deg,
    rgba(51, 59, 58, 1) 0%,
    rgba(55, 65, 65, 1) 25%,
    rgba(64, 54, 43, 1) 75%,
    rgba(33, 26, 23, 1) 100%
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
  -webkit-font-smoothing: antialiased;
  min-height: 100vh;
  max-height: 100vh;
}

html,
body {
  @include media-breakpoint-down(md) {
    // position: fixed;
    overflow: hidden;
    pointer-events: none;
    height: 100vh;
    // min-height: calc(100% + env(safe-area-inset-top));
  }
}

// body {
//   @include media-breakpoint-down(md) {
//     position: relative;
//   }
// }

#root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  height: 100%;
}

.media-backdrop .lazy-load-image-background.blur.lazy-load-image-loaded > img {
  opacity: 0.3;
}

.desktop-only {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.align-center {
  text-align: center;
}
