.media {
  &-wrap {
    position: relative;
  }
  &-top {
    z-index: 3;
    display: flex;
    position: relative;
    padding-top: 0;
    transition: padding 0.3s ease;
    padding-top: 50px;
    // margin: -25px 0;

    @include media-breakpoint-up(lg) {
      margin: 0;
      padding-top: 0;
      // height: 250px;
    }

    &.show-trailer {
      padding-top: 10vh;

      @include media-breakpoint-up(lg) {
        padding-top: 40vh;
      }

      .media-backdrop {
        background: black;

        &:after {
          opacity: 1;
        }

        img {
          opacity: 0;
        }
      }

      .media-details {
        opacity: 0;
        pointer-events: none;
      }

      .media-poster {
        transform: translateX(-100%);
      }

      .media-trailer--close {
        transform: translateX(0);
      }
    }
  }

  &-meta {
    margin-bottom: 20px;

    p {
      margin: 0;
      display: flex;
      align-items: center;
      font-size: 14px;
    }

    span {
      margin-right: 20px;
    }

    svg {
      height: 12px;
      margin-right: 5px;

      path {
        fill: rgba(white, 0.5);
      }
    }
  }

  &-logo {
    height: 80px;
    width: auto;
    max-width: 100%;
    object-fit: contain;
    // margin-bottom: -50px;

    @include media-breakpoint-up(lg) {
      height: 150px;
      margin-bottom: 30px;
    }

    &.placeholder {
      background: rgba($grey-medium, 0.1);
      height: 60px;
      width: 150px;

      @include media-breakpoint-up(lg) {
        height: 100px;
        width: 300px;
      }
    }

    &__small {
      height: 80px;
      width: auto;
    }
  }

  &-poster {
    width: 100px;
    min-width: 100px;
    position: relative;
    border-radius: 5px;
    height: 150px;
    transition: transform 0.3s ease;

    span {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      width: 150px;
      min-width: 150px;
      height: 230px;
    }

    @include media-breakpoint-up(lg) {
      width: 25%;
      min-width: 25%;
      margin-bottom: -50px;
      margin-top: 50px;
      height: initial;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: -100px;
      margin-top: 0;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 500px;
      min-width: 0;
    }

    &__cap {
      position: relative;

      @include media-breakpoint-up(lg) {
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(100% - 100px);
          box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.5);
          z-index: -1;
        }
      }
    }

    &--inner {
      padding-bottom: 150%;
      position: relative;
      width: 100%;
      height: 100%;
      height: 0;
      background: #484e50;

      border-radius: 5px;
      overflow: hidden;

      @include media-breakpoint-up(lg) {
        background: $dark-grey;
      }

      @include media-breakpoint-up(lg) {
        padding-bottom: 0;
        position: relative;
        padding-bottom: 150%;
      }
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
    }
  }

  &-details {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    padding-left: 25px;
    transition: opacity 0.3s ease;

    @include media-breakpoint-up(lg) {
      padding-left: 50px;
      padding-top: 50px;
    }

    .media-title {
      @extend .main-title;

      line-height: 36px;

      @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 18px;
      }
    }

    &--top {
      @include media-breakpoint-down(md) {
        margin-top: auto;
        margin-bottom: 40px;
      }

      @include media-breakpoint-up(lg) {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  &--actions {
    display: none;

    @include media-breakpoint-up(lg) {
      display: flex;
      margin-bottom: 50px;
      margin-top: auto;

      .btn {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }

    &__mob {
      display: flex;
      margin-bottom: 10px;
      flex-wrap: wrap;

      .btn {
        margin-right: 10px;
        margin-bottom: 10px;
        font-size: 8px;
        padding: 0 7px;

        @include media-breakpoint-up(sm) {
          font-size: 9px;
        }

        @include media-breakpoint-up(md) {
          font-size: 12px;
          padding: 0 20px;
          height: 35px;
        }

        svg {
          display: none;

          @include media-breakpoint-up(sm) {
            display: block;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &-backdrop {
    position: absolute;
    top: -25px;
    left: -25px;
    width: calc(100% + 50px);
    height: 100%;
    opacity: 1;
    z-index: -1;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: black;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    @include media-breakpoint-up(lg) {
      top: -50px;
      left: -50px;
      width: calc(100% + 100px);
      height: calc(100% + 50px);
      padding: 0;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.3;
    }
  }

  &-cast {
    padding-top: 25px;
    border-bottom: solid 1px #ffffff17;
    border-top: solid 1px #ffffff17;
    padding-bottom: 25px;
    margin-bottom: 25px;

    @include media-breakpoint-up(lg) {
      // margin-top: 0;
      padding-top: 50px;
      margin-bottom: 50px;
    }

    &--inner {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
    }

    &--item {
      width: calc(100% / 3);
      padding: 15px;

      @include media-breakpoint-up(lg) {
        width: calc(100% / 6);
      }
    }
  }

  &-action {
    margin-top: 10px;
    flex-direction: column;
    display: none;

    @include media-breakpoint-up(lg) {
      margin-top: 10px;
      flex-direction: row;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }

    .btn {
      margin-bottom: 10px;
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }

  &-content {
    // padding-top: 25px;

    @include media-breakpoint-up(lg) {
      padding-top: 0;
    }

    .quick-view {
      display: flex;
      flex-wrap: wrap;
    }

    .side-content {
      @include media-breakpoint-up(lg) {
        width: 25%;
        margin-top: 50px;
        padding-top: 20px;
      }

      @include media-breakpoint-up(xl) {
        margin-top: 100px;
      }

      @include media-breakpoint-up(xxl) {
        max-width: 500px;
      }

      .resolutions {
        display: flex;
        justify-content: center;
        svg {
          height: 50px;
          margin: 0 8px;
          fill: rgba(255, 255, 255, 0.4);
        }
      }
    }

    .info {
      padding-top: 10px;

      @include media-breakpoint-up(lg) {
        padding-left: 50px;
        width: 75%;
        padding-top: 50px;
        padding-bottom: 30px;
      }

      .details {
        margin-bottom: 20px;
        margin-top: 10px;

        @include media-breakpoint-up(lg) {
          margin-top: 0;
          margin-bottom: 20px;
        }

        &--item {
          display: flex;
          align-items: center;

          p {
            margin: 0;
            font-size: 14px;
            font-weight: 500;
          }
        }

        .hide-mb {
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        .spacer {
          margin: 0 10px;
          color: $grey-medium;

          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }
    }

    .media-crew {
      display: flex;
      flex-wrap: wrap;

      &--item {
        margin: 0 30px 15px 0;

        @include media-breakpoint-up(lg) {
          margin: 0 50px 15px 0;
        }
      }

      .crew {
        &-credit {
          color: $grey-light;
        }
      }

      p {
        margin: 0;
      }

      a {
        @extend p;
      }
    }

    .overview {
      color: white;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 0;

      @include media-breakpoint-up(lg) {
        font-size: 16px;
        line-height: 26px;
        font-weight: 300;
      }

      @include media-breakpoint-up(xl) {
        padding-right: 100px;
      }

      @include media-breakpoint-up(xxl) {
        width: 1000px;
        max-width: 100%;
      }
    }
  }

  &-trailer {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 2;
    padding-bottom: 56.25%;
    transform: translateY(-50%);

    & > div {
      position: absolute;
      top: 0;
      left: 0;

      @include media-breakpoint-up(lg) {
        pointer-events: none;
      }
    }

    iframe {
      // object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &--close {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 200;
      background: $primary;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
      transform: translateX(100px);
      transition: transform 0.3s ease, background 0.3s ease;

      @include media-breakpoint-up(lg) {
        width: 40px;
        height: 40px;

        &:hover {
          background: $primaryDark;
        }
      }

      svg {
        height: 20px;
        color: black;

        @include media-breakpoint-up(lg) {
          height: 30px;
        }
      }
    }
  }
}

.tv-season-grid {
  display: flex;
  flex-wrap: nowrap;
  margin: 25px -20px 25px -10px;
  overflow-x: auto;

  @include media-breakpoint-up(lg) {
    margin: 50px -15px;
    flex-wrap: wrap;
    overflow: initial;
  }

  &--item {
    width: 180px;
    min-width: 180px;
    padding: 10px;

    @include media-breakpoint-up(lg) {
      padding: 15px;
      width: calc(100% / 6);
    }
  }
}

.no-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-size: 200px;
  // background-color: $dark-grey;
  background: $dark-grey;
  background: linear-gradient(
    225deg,
    rgba($grey-light, 0.2) 0%,
    rgba($grey-light, 0.2) 20%,
    rgba($black, 0.2) 80%,
    rgba($black, 0.2) 100%
  );
}

.text-placeholder {
  background: rgba($grey-medium, 0.2);
  min-height: 24px;

  &.overview {
    height: 72px;
  }

  &.sub-title {
    width: 100px;
  }

  &.details {
    width: 200px;
    max-width: 100%;
  }
}

.genre-placeholder {
  height: 21px;
  width: 70px;
  opacity: 0.3;
}

.mob-btn-placeholder {
  .btn {
    width: 80px;
  }
}

.detail {
  &--wrap {
    @include media-breakpoint-up(lg) {
      width: 75%;
    }
  }

  &--content {
    padding-top: 10px;
    @include media-breakpoint-up(lg) {
      padding: 50px 0 50px 50px;
    }
  }

  &--bar {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
      margin-bottom: 30px;
    }

    p {
      margin: 0;
      font-size: 10px;
      font-weight: 500;
      display: flex;
      align-items: center;

      @include media-breakpoint-up(lg) {
        font-size: 12px;
      }
    }

    .rating,
    .item-val {
      margin-left: 5px;

      &.d-nm {
        @include media-breakpoint-down(md) {
          margin-left: 0;
        }
      }
    }

    .rating-icon {
      display: inline-flex;
      // margin-right: 5px;

      svg {
        height: 12px;
        width: auto;

        @include media-breakpoint-up(lg) {
          height: 14px;
          width: 28px;
        }
      }
    }

    &--sep {
      padding: 0 5px;
      font-size: 10px;
      line-height: 22px;
      font-weight: 500;

      @include media-breakpoint-up(lg) {
        padding: 0 10px;
        font-size: 12px;
      }
    }

    &--item {
      height: 50px;
      padding: 0 20px;
      padding-right: 50px;

      .title {
        font-weight: 700;
      }

      &__icon {
        background: $dark-grey;
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 20px;
          fill: $primary;
        }
      }
    }
  }
}

.genre {
  &--wrap {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
      margin-bottom: 20px;
    }
  }

  &--item {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 5px 10px;
    // border: solid 1px white;
    font-size: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background: rgba($grey-light, 0.2);
    color: white;
    transition: background 0.3s ease;

    &:hover {
      background: rgba($grey-light, 0.4);
    }

    svg {
      height: 10px;
      margin-right: 5px;
    }
  }
}

.networks,
.companies {
  &--wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &--item {
    margin-right: 10px;
    margin-bottom: 10px;

    @include media-breakpoint-up(lg) {
      margin-right: 20px;
      margin-bottom: 20px;
    }

    a {
      display: block;

      &:hover {
        img {
          opacity: 1;
        }
      }
    }

    img {
      transition: opacity 0.3s ease;
      max-height: 20px;
      max-width: 60px;
      height: 20px;
      object-fit: contain;
      // filter: brightness(0) invert(1);
      opacity: 0.5;

      @include media-breakpoint-up(lg) {
        max-height: 30px;
        max-width: 100px;
        height: 30px;
        object-fit: contain;
      }
    }
  }
}

.companies,
.networks {
  &--item {
    a:hover {
      img {
        opacity: 1;
      }
    }
  }
}

// .companies {
//   &--item {
//     a:hover {
//       opacity: 0.6;
//     }

//     img {
//       opacity: 0.3;
//       filter: brightness(0) invert(1);
//     }
//   }
// }
