.review {
  &--wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;

    @include media-breakpoint-up(lg) {
      background: rgba(0, 0, 0, 0.6);
    }

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &--inner {
    width: 500px;
    max-width: calc(100% - 40px);
    background: $black;
    border-radius: 5px;
    overflow: hidden;
  }

  &--top {
    background: $primary;
    padding: 10px 20px;

    h3 {
      font-size: 15px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }

  &--main {
    padding: 20px;

    .save-review {
      margin-left: 10px;
    }

    .stars-wrap {
      display: flex;
      margin-bottom: 15px;

      &:hover {
        .star {
          opacity: 1;
        }
      }

      .star {
        opacity: 0.5;
        padding-right: 5px;
        cursor: pointer;

        svg {
          height: 20px;

          path {
            fill: $primary;
          }

          @include media-breakpoint-up(lg) {
            height: 30px;
          }
        }

        &.active {
          opacity: 1;
        }

        &:hover {
          opacity: 1;

          & ~ .star {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.reviews-list {
  &--wrap {
    padding: 30px 0;
  }

  &--item {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  &--thumb {
    padding-right: 30px;

    .thumb {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 50px;
      height: 50px;
      width: 50px;
      margin-bottom: 20px;

      @include media-breakpoint-up(lg) {
        height: 50px;
        width: 50px;
        margin-bottom: 0;
      }
    }
  }

  &--content {
    padding-bottom: 30px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 50px;
      padding-left: 30px;
      border-left: solid 1px rgba(255, 255, 255, 0.1);
      width: calc(100% - 100px);
    }

    p {
      margin-bottom: 10px;

      &.small {
        font-size: 12px;
        line-height: 1.6;
      }

      &.content-cap {
        max-height: calc(19px * 10);
        overflow: hidden;
      }
    }

    .stars-wrap {
      display: flex;
      margin-bottom: 15px;

      .star {
        opacity: 0.5;
        padding-right: 5px;
        cursor: pointer;

        &:hover {
          opacity: 0.5 !important;
        }

        svg {
          height: 10px;

          path {
            fill: $primary;
          }
        }

        &.active {
          opacity: 1 !important;
        }
      }
    }
  }
}
